import Dropdown from "../components/Dropdown";

function DropdownPage({ changeSortBy, changeSortOrder, sortBy, sortOrder }) {

    const sortByOptions = [
        { label: 'Employee ID', value: 'EmployeeID'},
        { label: 'Device Model', value: 'DeviceModel'},
        { label: 'Device OS Version', value: 'DeviceOSVersion'},
        { label: 'Device Serial Number', value: 'DeviceSerialNumber'},
        { label: 'Device IMEI', value: 'DeviceIMEI'},
        { label: 'BJIT Asset ID', value: 'BJITAssetId'}
    ];

    const sortOrderOptions = [
        { label: "ASC", value: 'asc'},
        { label: "DESC", value: 'desc'}
    ];
 

    return (
    <div className="flex mx-5 my-auto">
        <Dropdown width={192} options={sortByOptions} value={sortBy} onChange={changeSortBy}/>
        <Dropdown width={96} options={sortOrderOptions} value={sortOrder} onChange={changeSortOrder}/>
    </div>
    ); 
}

export default DropdownPage;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaPjB7NbM3cKyvZvz0S5pd4tSrTYQ-Dyw",
  authDomain: "infocapturepro-5d5f1.firebaseapp.com",
  projectId: "infocapturepro-5d5f1",
  storageBucket: "infocapturepro-5d5f1.appspot.com",
  messagingSenderId: "533151146850",
  appId: "1:533151146850:web:c9cbb01faeffbca3e0423b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

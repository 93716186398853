import ColumnHeader from "../utils/ColumnHeader";
import Table from "./Table";

function SortableTable({ sortBy, sortOrder, deviceInfoList }) {

    const config = ColumnHeader();
    let sortedData = deviceInfoList;


    if(sortOrder && sortBy) {
        const { sortValue } = config.find((column) => column.label === sortBy.label);
        sortedData = [...deviceInfoList].sort((a,b) => {
            const valA = sortValue(a);
            const valB = sortValue(b);

            const reverseOrder = sortOrder.value === 'asc' ? 1 : -1;

            if(typeof(valA) === 'string') {
                return valA.localeCompare(valB) * reverseOrder;
            }

            return (valA - valB) * reverseOrder;
        });
    }

    return (
        <Table deviceInfoList={sortedData} />
    );
}

export default SortableTable;
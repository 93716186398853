import { useEffect, useState } from "react";
import { fetchAdmin } from "../network/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function LoginPage({ authorized }) {
 
    const title = "BJIT Device List";
    const errorClass = "text-orange-400 text-xs justify-start";

    const initialValues = {
        email: "",
        password: ""
    };

    const [formValue, setFormValue] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const notify = () => toast.error("Wrong email or password!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    let isAlert = true;


    useEffect(()=> {
        if(Object.keys(formErrors).length === 0 && isSubmit) {
            fetchingAdminData();
        }
        setIsSubmit(false);
    }, [formErrors]);

    async function fetchingAdminData() {
        const admins = await fetchAdmin();
        isAlert = true;
        admins.forEach((admin) => {
            if(admin.email === formValue.email && admin.password === formValue.password) {
                isAlert = false;
                authorized(true);
            }
        });
        if(isAlert) {
            notify();
        }
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setFormValue({...formValue, [name]: value});
        //console.log(formValue);
    }

    function handleSubmit(event) {
        event.preventDefault();
        setFormErrors(validate(formValue));
        setIsSubmit(true);
    } 

    function validate(values) {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(!values.email) {
            errors.email = "Email is required!";
        }
        else if(!regex.test(values.email)) {
            errors.email = "Email is in wrong format.(e.g. XYZ@xyz.com)";
        }
        if(!values.password) {
            errors.password = "Password is required!";
        }
        return errors;
    }

    return (
        <div className="flex flex-col h-screen items-center justify-center">
            { isAlert && <ToastContainer 
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />}
            <form className="flex flex-col items-center p-3 bg-gray-700 w-1/4 rounded" onSubmit={handleSubmit}>
                <label className="text-white text-4xl">{title}</label>
                <input
                    className="bg-gray-200 mt-5 p-2 outline-orange-400 rounded w-[90%]"
                    placeholder="Email"
                    type="text"
                    name="email"
                    value={formValue.email}
                    onChange={handleChange}
                />
                <p className={errorClass}>{formErrors.email}</p>
                <input 
                    className="bg-gray-200 mt-5 p-2 outline-orange-400 rounded w-[90%]"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={formValue.password}
                    onChange={handleChange}
                />
                <p className={errorClass}>{formErrors.password}</p>
                <button className="bg-orange-400 hover:bg-orange-500 text-white mt-5 p-2 rounded w-[90%] mb-8" onClick={handleSubmit}>
                    Login
                </button>
            </form>
        </div>
    );
}

export default LoginPage;
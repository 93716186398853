import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
// import firebase from "firebase/app";

const fetchData = async () => {

    const fetchedData = [];

    await getDocs(collection(db, "devices"))
    .then((querySnapshot) => {
        //console.log(querySnapshot);
        // const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id}));
        querySnapshot.docs.map((doc) => {
            // const tempData = (doc._document.data.value.mapValue.fields);
            // console.log("tempData: ", tempData);
            // console.log("row data: ", doc._document.data.value.mapValue.fields)
            fetchedData.push(doc._document.data.value.mapValue.fields)
        })
    });

    return fetchedData;
}

const fetchAdmin = async () => {
    const fetchedAdmin = [];
    await getDocs(collection(db, "admin"))
    .then((querySnapshot) => {
        //console.log("admin data: ", querySnapshot);
        querySnapshot.docs.map((doc) => {
            const data = doc._document.data.value.mapValue.fields;
            const admin = {
                "email": data.email.stringValue,
                "password": data.password.stringValue
            };
            fetchedAdmin.push(admin);
        });
    });

    return fetchedAdmin;
}


export { fetchData, fetchAdmin };
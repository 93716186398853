function ColumnHeader() {
    const config = [
        {
            label: "Employee ID",
            render: (device) => device.EmployeeID,
            sortValue: (device) => device.EmployeeID
        },
        {
            label: "Device Name",
            render: (device) => device.DeviceName
        },
        {
            label: "Device Model",
            render: (device) => device.DeviceModel,
            sortValue: (device) => device.DeviceModel
        },
        {
            label: "SDK Version",
            render: (device) => device.SDKVersion
        },
        {
            label: "Device OS Version",
            render: (device) => device.DeviceOSVersion,
            sortValue: (device) => device.DeviceOSVersion
        },
        {
            label: "Device Serial Number",
            render: (device) => device.DeviceSerialNumber,
            sortValue: (device) => device.DeviceSerialNumber
        },
        {
            label: "Device IMEI",
            render: (device) => device.DeviceIMEI,
            sortValue: (device) => device.DeviceIMEI
        },
        {
            label: "Device ID",
            render: (device) => device.DeviceID
        },
        {
            label: "Device Manufacturer",
            render: (device) => device.DeviceManufacturer
        },
        {
            label: "Battery Health",
            render: (device) => device.BatteryHealth
        },
        {
            label: "BJIT Asset ID",
            render: (device) => device.BJITAssetId,
            sortValue: (device) => device.BJITAssetId
        },
        {
            label: "Defect",
            render: (device) => device.Defect
        }
    ];
    return (config);
};

export default ColumnHeader;
import { useEffect, useState } from "react";
import { fetchData } from "./network/Api";
// import Table from "./components/Table";
import SortableTable from "./components/SortableTable";
import TableHeader from "./components/TableHeader";
import ConvertToJson from "./utils/ConvertToJson";
import { dummyData } from "./Model/dataModel";
import LoginPage from "./components/LoginPage";
import Footer from "./components/Footer";


function App() {

    const [deviceInfoList, setDeviceInfoList] = useState([]);
    const [searchedInfoList, setSearchedInfoList] = useState(deviceInfoList);
    const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true" ? true : false;
    const [loginFlag, setLoginFlag] = useState(isLoggedIn);


    useEffect(() => {
        if(loginFlag) {
            fetchingData();
            
            // using Dummy Data here!
            // setDeviceInfoList(dummyData);
            // setSearchedInfoList(dummyData);
            //fetchingAdminData();
            
            //console.log("download data!");
        }
    }, [pageRefreshFlag, loginFlag]);
    
    async function fetchingData() {
        const data = await fetchData();
        // console.log("data: ", data);
        const convertedData = ConvertToJson(data);
        // console.log("fetchedData: ", convertedData);
        setDeviceInfoList(convertedData);
        setSearchedInfoList(convertedData);
    }


    function authorized(val) {
        sessionStorage.setItem("isLoggedIn", val);
        setLoginFlag(val);
    }

    function pageRefresh() {
        setDeviceInfoList([]);
        setSearchedInfoList([]);
        setSortBy(null);
        setSortOrder(null);
        setTimeout(() => {
            setPageRefreshFlag((val) => !val);
        }, 500);
    }

    function getSearchKey(searchKey) {
        const tempData = deviceInfoList.filter((deviceInfo) => {
            if(searchKey === "") {
                return deviceInfo;
            }
            const temp = Object.keys(deviceInfo).map((device) => {
                return deviceInfo[device].toLowerCase().includes(searchKey.toLowerCase()) ? 1 : 0;    
            });
            let cnt = 0;
            temp.map((val) => cnt += val);
            if(cnt > 0) return deviceInfo;
        });
        setSearchedInfoList(tempData);
    }

    function changeSortBy(option) {
        setSortBy(option);
        setSortOrder(null);
    }

    function changeSortOrder(option) {
        setSortOrder(option);
    }

    if(loginFlag === true) {
        return (
            <div className="flex flex-col min-h-screen">
                <TableHeader 
                    data={searchedInfoList} 
                    pageRefresh={pageRefresh} 
                    getSearchKey={getSearchKey} 
                    authorized={authorized}
                    changeSortBy={changeSortBy}
                    changeSortOrder={changeSortOrder}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                {/* <Table deviceInfoList={searchedInfoList}/> */}
                <SortableTable sortBy={sortBy} sortOrder={sortOrder} deviceInfoList = {searchedInfoList}/>
                <Footer />
            </div>
        );
    }
    else {
        return (
            <div>
                <LoginPage authorized={authorized}/>
            </div>
        );
    }
};

export default App;
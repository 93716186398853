function LogoutButton({ authorized }) {

    return (
        <button 
        className="bg-orange-400 hover:bg-orange-500  my-auto p-2.5 rounded w-20 text-center text-sm"
            onClick={() => authorized(false)}
        >
            Logout
        </button>
    );
}

export default LogoutButton;
import { useState } from "react";

function SearchBar({ getSearchKey }) {

    const [innerValue, setInnerValue] = useState("");

    // function handleSubmit(event) {
    //     event.preventDefault();
    //     getSearchKey(innerValue);
    //     setInnerValue("");
    // }

    const handleOnChange = (event) => {
        const newKey = event.target.value;  
        setInnerValue(newKey);
        getSearchKey(newKey);
    }

    return (
        <form onSubmit={(event) => {event.preventDefault()}}>
            <input
                className="border border-white rounded p-2.5 my-auto text-black focus:outline-none text-sm"
                placeholder="search here..."
                type="text"
                value={innerValue}
                onChange={handleOnChange}
            />
        </form>
    );
}

export default SearchBar;
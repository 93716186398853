import { mapToDevice } from "./MapToDevice";

function ConvertToJson(data) {
    const convertedData = data.map((deviceInfo) => {
        const row = {
            EmployeeID: deviceInfo.user.mapValue.fields.employeeId.stringValue ?? "-",
            DeviceName: deviceInfo.deviceName.stringValue ?? "-",
            DeviceModel: mapToDevice(deviceInfo.deviceModel.stringValue) ?? "-",
            SDKVersion: deviceInfo.sdkVersion.stringValue ?? "-",
            DeviceOSVersion: deviceInfo.deviceOsVersion.stringValue ?? "-",
            DeviceSerialNumber: deviceInfo.deviceSerialNumber.stringValue ?? "-",
            DeviceIMEI: deviceInfo.deviceIMEI.stringValue ?? "-",
            DeviceID: deviceInfo.deviceID.stringValue ?? "-",
            DeviceManufacturer: deviceInfo.deviceManufacturer.stringValue ?? "-",
            BatteryHealth: deviceInfo.batteryHealth.stringValue ?? "-",
            BJITAssetId: deviceInfo.bjitAssetId.stringValue ?? "",
            Defect: deviceInfo.defect.stringValue ?? "-"
        };

        return row;
    });
    return convertedData;
}

export default ConvertToJson;
function Footer() {

    const version = "1.1.1";
    const message = `v${version} © 2023 BJIT Group. All Rights Reserved.`

    const _class = `bg-gray-700 text-white p-3 mt-auto text-xs text-center`;

    return (
        <div className={_class}>
            {message}
        </div>
    );
}

export default Footer;
import { CSVLink } from "react-csv";
import SearchBar from "./SearchBar";
import LogoutButton from "./LogoutButton";
import DropdownPage from "./DropdownPage";

function TableHeader({ 
    data, 
    pageRefresh, 
    getSearchKey, 
    authorized, 
    changeSortBy,
    changeSortOrder,
    sortBy,
    sortOrder }) {

    const headerText = "BJIT Device List";
    
    return (
        <div className="bg-gray-700 text-white p-2.5 flex justify-between">
            <h1 className="text-4xl my-auto ml-8"><button onClick={pageRefresh}>{headerText}</button></h1>
            <div className="p-2 flex justify-between">
                <DropdownPage 
                    changeSortBy={changeSortBy}
                    changeSortOrder={changeSortOrder}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <SearchBar getSearchKey={getSearchKey}/>
                <CSVLink className="bg-orange-400 hover:bg-orange-500 mr-8 ml-20 my-auto p-2.5 rounded w-20 text-center text-sm" data={data} filename={headerText} header={headerText}>Export</CSVLink>
                <LogoutButton authorized={authorized}/>
            </div>
        </div>
    );
}

export default TableHeader;
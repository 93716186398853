import ColumnHeader from '../utils/ColumnHeader';

function Table({ deviceInfoList }) {

    const config = ColumnHeader();

    const renderConfig = config.map((data, index) => {
        return (
            <th className="p-2" key={index}>{data.label}</th>
        );
    });

    const renderBody = deviceInfoList.map((deviceInfo, index) => {
        const renderCellData = config.map((col) => {
            return (
                <td className='p-2' key={col.label}>{col.render(deviceInfo)}</td>
            );
        });

        return(
            <tr className='border-b text-center text-xs' key={index}>{renderCellData}</tr>
        );
    });


    return(
        <div className='overflow-x-auto'>
            { 
                deviceInfoList.length !== 0 ? (
                    <table className="table-auto border-spacing-2 p-1 mx-auto w-full">
                        <thead>
                            <tr className="border-b-1 bg-gray-300 text-center text-sm">
                                {renderConfig}
                            </tr>
                        </thead>
                        <tbody>
                            {renderBody}
                        </tbody>
                    </table>
                ) : (
                    <div className='text-orange-500 text-3xl text-center p-20'>Something went wrong! No data available right now.</div>
                )
            }
        </div>
    );
};

export default Table;
import { useEffect, useRef, useState } from 'react';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import Panel from './Panel';

function Dropdown({ width, options, value, onChange }) {
    // const _className = `w-[${width}px] relative mx-1`;
    let _className = "relative mx-1";
    if(width === 192) {
        _className = _className + " w-48";
    }
    else {
        _className = _className + " w-20";
    }
    
    const [isOpen, setIsOpen] = useState(false);
    const divEl = useRef();

    useEffect(() => {
        const handler = (event) => {
            if(!divEl.current) {
                return;
            }
            if(!divEl.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    function handleOptionClick(option) {
        setIsOpen((curr) => !curr);
        onChange(option);
    }

    const renderedOptions = options.map((option) => {
        return (
            <div className='hover:bg-orange-400 rounded cursor-pointer p-1' key={option.value} onClick={() => handleOptionClick(option)}>
                {option.label}
            </div>
        );
    });

    return (
        <div ref={divEl} className={_className}>
            <Panel 
                className='flex justify-between items-center cursor-pointer'
                onClick={() => setIsOpen((curr) => !curr)}
            >
                {value!==null ? value.label : (width === 192)? "Sort By": "Order"}
                {(!isOpen) ? <GoChevronDown /> : <GoChevronUp />}
            </Panel>
            
            {(isOpen) && 
                <Panel className='absolute top-full'>
                    {renderedOptions}
                </Panel>
            }
        </div>
    );
}

export default Dropdown;